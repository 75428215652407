import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from "react";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Button from "@mui/material/Button";
import { NxtIconButton } from "components/common/nxt-icon-button/nxt-icon-button.component";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon, ListItemText, useTheme } from "@mui/material";

import { AxiosResponse } from "axios";
import { PageTitle } from "components/common/Text";
import { getUserList, UserListTypes } from "api/PowerUserHelpers";
import { convertCognitoAttr, getCurrentPage, getFilteredListNew, getSortList } from "utils/util-functions";
import { useAuth } from "hooks/useProvideAuth";
import { TableBodyCell } from "components/table/table.style";
import { deleteUser } from "api/AdminHelpers";
import { showSuccess } from "utils/notifications";
import CustomTable from "components/table/table.component";
import NewAdminDialog from "components/power-user/new-admin-dialog.component";
import TableFilterNew from "components/table/TableFilterNew.component";
import useConfirm from "hooks/useConfirm";
import useOrganizations from "hooks/useOrganizations";
import ADMIN_COLUMNS, { AdminListTypes } from "./Admins.config";
import style from "./administration.module.css";

const AdminsPage = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [adminList, setAdminList] = useState<AdminListTypes[]>([]);
  const [isOpenNewAdminModal, setIsOpenNewAdminModal] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");
  const [filterColumn, setFilterColumn] = useState<string>("");
  const [filterWord, setFilterWord] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState<AdminListTypes | null>();
  const open = Boolean(anchorEl);

  const filteredList = useMemo(
    () => getFilteredListNew(adminList, filterColumn, filterType, filterWord),
    [adminList, filterColumn, filterWord, filterType],
  );
  const totalAdminCount = useMemo(() => filteredList?.length || 0, [filteredList]);

  const sortedList = useMemo(() => {
    const sortColumn = ADMIN_COLUMNS.find(({ id }) => id === sortBy);
    return getSortList(filteredList, sortBy, sortOrder, sortColumn?.type);
  }, [sortBy, sortOrder, filteredList]);

  const paginatedList = useMemo(() => getCurrentPage(sortedList, page, rowsPerPage), [sortedList, page, rowsPerPage]);

  const filterColumns = ADMIN_COLUMNS.filter(({ filter }) => filter);

  const { sub: userId }: Record<string, string> = useAuth();
  const { orgList: orgListData } = useOrganizations(userId, true);

  const orgList = orgListData.map((g: Record<string, string>) => ({ value: g.GroupName, label: g.GroupName }));

  const theme = useTheme();
  const [getConfirmation, Confirmation] = useConfirm();

  const adminColumns = useMemo(() => {
    const tempCols = [...ADMIN_COLUMNS];
    tempCols.pop();
    return tempCols;
  }, []);

  const fetchAdmins = async (token?: string) => {
    const body: UserListTypes = { username: userId, rolename: "admin", token };

    try {
      const { data } = await getUserList(body);
      const newUsers = (data?.Users ?? []).map((user: AdminListTypes) => ({
        ...user,
        ...convertCognitoAttr(user.Attributes),
      }));

      if (token) {
        setAdminList((value: AdminListTypes[]) => [...value, ...newUsers]);
      } else {
        setAdminList(newUsers);
      }

      if (data.PaginationToken) {
        await fetchAdmins(data.PaginationToken);
      }
    } catch (error) {
      // error handling
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAdmins();
  }, []);

  const onNewAdmin = () => {
    setIsOpenNewAdminModal(true);
  };

  const onClickMenu = (event: BaseSyntheticEvent, row: AdminListTypes) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const onDeleteAdmin = async () => {
    setAnchorEl(null);

    const isConfirm = await getConfirmation({
      title: "Delete Admin",
      content: "Are you sure that you want to delete the selected admin?",
    });

    if (isConfirm) {
      try {
        const { data }: AxiosResponse<any, any> = await deleteUser({
          username: userId,
          userEmail: currentRow?.email,
        });

        showSuccess(data?.message);
        await fetchAdmins();
      } catch (error) {
        console.warn("On Delete Admin", error);
      }
    }
  };

  const handleRequestSort = (order: string, orderBy: string) => {
    setSortOrder(order);
    setSortBy(orderBy);
  };

  const onSearch = (column: string, columnType: string, searchContent: string) => {
    setFilterColumn(column);
    setFilterWord(searchContent);
    setFilterType(columnType);
    setPage(0);
  };

  const onResetFilter = () => {
    setFilterColumn("");
    setFilterWord("");
    setFilterType("");
    setPage(0);
  };

  return (
    <>
      <PageTitle>Administrators</PageTitle>

      <div className={style.barContainer}>
        <div className={style.filterContainer}>
          {adminList?.length > 10 ? (
            <TableFilterNew columns={filterColumns} onSearch={onSearch} onReset={onResetFilter} />
          ) : (
            <div />
          )}
        </div>
        <div className={style.buttonOptions}>
          <NxtIconButton icon={<AddCircleOutlineIcon />} text="New Admin" onClick={onNewAdmin} />
        </div>
      </div>
      <CustomTable
        boxShadow
        rowsPerPageOptions={[10, 25, 100]}
        totalCount={totalAdminCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(pageNum) => setPage(pageNum)}
        onRowsPerPageChange={(rowPerPage) => setRowsPerPage(rowPerPage)}
      >
        <CustomTable.Header columns={ADMIN_COLUMNS} onRequestSort={handleRequestSort} />

        <CustomTable.Body isLoading={isLoading} deviceData={paginatedList}>
          {paginatedList &&
            paginatedList.map(
              (row: AdminListTypes) =>
                row && (
                  <TableRow key={row.Username}>
                    {adminColumns.map((column) => {
                      const value = row[column.id as keyof AdminListTypes];

                      return (
                        <TableBodyCell key={column.id}>{column.format ? column.format(value) : value}</TableBodyCell>
                      );
                    })}
                    <TableBodyCell style={{ width: 30 }}>
                      <Button onClick={(e) => onClickMenu(e, row)} style={{ color: theme.palette.common.black }}>
                        <MoreVertIcon />
                      </Button>
                    </TableBodyCell>
                  </TableRow>
                ),
            )}
        </CustomTable.Body>
      </CustomTable>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onDeleteAdmin}>
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <NewAdminDialog
        open={isOpenNewAdminModal}
        orgList={orgList}
        userId={userId}
        onClose={() => setIsOpenNewAdminModal(false)}
        onAdd={async () => fetchAdmins()}
      />

      <Confirmation />
    </>
  );
};

export default AdminsPage;
