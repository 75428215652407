import React, { useMemo, useState, useEffect, BaseSyntheticEvent } from "react";
import TableRow from "@mui/material/TableRow";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { FieldValues, useForm, useFormState } from "react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon, ListItemText, useTheme } from "@mui/material";

import { NxtIconButton } from "components/common/nxt-icon-button/nxt-icon-button.component";
import { PageTitle } from "components/common/Text";
import { TableBodyCell } from "components/table/table.style";
import {
  convertCognitoAttr,
  getCurrentPage,
  getFilteredListNew,
  getSortList,
  nameValidationCheck,
} from "utils/util-functions";
import { PageFooter } from "components/common/Wrapper";
import { PrimaryButton, GhostButton } from "components/common/Buttons";
import { FieldError, PhoneInputWrapper, StyledTextField } from "components/common/Input";
import CustomTable from "components/table/table.component";
import { emailRegex } from "utils/constants";
import { addPowerUser, getPowerUserList } from "api/PowerUserHelpers";
import { deleteUser } from "api/AdminHelpers";
import { useAuth } from "hooks/useProvideAuth";
import { showSuccess } from "utils/notifications";
import useConfirm from "hooks/useConfirm";
import TableFilterNew from "components/table/TableFilterNew.component";
import POWER_USER_COLUMNS from "./PowerUsers.config";
import style from "./power-users.module.css";
import CustomPhoneNumber from "../../../components/common/phone-input/phone-input.component";
import { AdminListTypes } from "../Admins/Admins.config";

const PowerUsersPage = (): JSX.Element => {
  const [isOpenNewUserModal, setIsOpenNewUserModal] = useState<boolean>(false);
  const [phoneNum, setPhoneNum] = useState<string>();
  const [isCreatingNewUser, setIsCreatingNewUser] = useState<boolean>(false);
  const [userList, setUserList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [filterColumn, setFilterColumn] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("");
  const [filterWord, setFilterWord] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState<AdminListTypes | null>(null);
  const open = Boolean(anchorEl);

  const { sub: userId }: Record<string, string> = useAuth();
  const theme = useTheme();
  const [getConfirmation, Confirmation] = useConfirm();

  const filterColumns = useMemo(() => POWER_USER_COLUMNS.filter(({ filter }) => filter), []);

  const filteredList = useMemo(
    () => getFilteredListNew(userList, filterColumn, filterType, filterWord),
    [userList, filterColumn, filterWord, filterType],
  );

  const sortedList = useMemo(() => {
    const sortColumn = POWER_USER_COLUMNS.find(({ id }) => id === sortBy);
    return getSortList(filteredList, sortBy, sortOrder, sortColumn?.type);
  }, [sortBy, sortOrder, filteredList]);

  const totalUserCount = useMemo(() => filteredList?.length || 0, [filteredList]);
  const paginatedList = useMemo(() => getCurrentPage(sortedList, page, rowsPerPage), [sortedList, page, rowsPerPage]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  }: FieldValues = useForm();
  const formState = useFormState({ control });

  const powerUserColumns = useMemo(() => {
    const tempCols = [...POWER_USER_COLUMNS];
    tempCols.pop();
    return tempCols;
  }, []);

  const fetchPowerUsers = async (token?: string): Promise<void> => {
    try {
      setIsLoading(true);
      const { data } = await getPowerUserList({ username: userId, token });
      const newUsers: string[] = (data?.Users ?? []).map((user: AdminListTypes) => ({
        ...user,
        ...convertCognitoAttr(user.Attributes || {}),
      }));
      if (token) {
        setUserList((value) => [...value, ...newUsers]);
      } else {
        setUserList(newUsers);
      }

      if (data.PaginationToken) {
        await fetchPowerUsers(data.PaginationToken);
      }
    } catch (error) {
      setUserList([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPowerUsers();
  }, []);

  const onNewPowerUser = () => {
    setValue("userName", "");
    setValue("email", "");
    setPhoneNum("");

    setIsOpenNewUserModal(true);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const onModalClose = (event: never, reason: string) => {
    if (reason !== "backdropClick") {
      setIsOpenNewUserModal(false);
    }
  };

  const onCreateNewUser = async ({ email, userName }: FieldValues) => {
    if (!phoneNum || !isPossiblePhoneNumber(phoneNum)) return;

    setIsCreatingNewUser(true);
    try {
      const { data: response } = await addPowerUser({
        username: userId,
        name: userName,
        phonenumber: phoneNum,
        email: email,
      });
      showSuccess(response?.message);

      fetchPowerUsers();
      setIsOpenNewUserModal(false);
    } catch (error) {
      // error handling
    } finally {
      setIsCreatingNewUser(false);
    }
  };

  const closeModal = () => {
    setIsOpenNewUserModal(false);
  };

  const onClickMenu = (event: BaseSyntheticEvent, row: AdminListTypes) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const onDeleteUser = async () => {
    setAnchorEl(null);

    const isConfirm = await getConfirmation({
      title: "Delete Power User",
      content: "Are you sure that you want to delete the selected power user?",
    });

    if (isConfirm) {
      try {
        const { data: response } = await deleteUser({
          username: userId,
          userEmail: currentRow?.email,
        });
        showSuccess(response?.message);
        fetchPowerUsers();
      } catch (error) {
        // error handling
      }
    }
  };

  const onSearch = (column: string, columnType: string, searchContent: string) => {
    setFilterColumn(column);
    setFilterWord(searchContent);
    setFilterType(columnType);
    setPage(0);
  };

  const onResetFilter = () => {
    setFilterColumn("");
    setFilterWord("");
    setFilterType("");
    setPage(0);
  };

  const handleRequestSort = (order: string, orderBy: string) => {
    setSortOrder(order);
    setSortBy(orderBy);
  };

  return (
    <>
      <PageTitle>Power Users</PageTitle>

      <div className={style.barContainer}>
        <div className={style.filterContainer}>
          <TableFilterNew columns={filterColumns} onSearch={onSearch} onReset={onResetFilter} />
        </div>
        <div className={style.buttonOptions}>
          <NxtIconButton onClick={onNewPowerUser} icon={<AddCircleOutlineIcon />} text="New Power User" />
        </div>
      </div>
      <CustomTable
        boxShadow
        rowsPerPageOptions={[10, 25, 100]}
        totalCount={totalUserCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(pageNum) => setPage(pageNum)}
        onRowsPerPageChange={(rowPerPage) => setRowsPerPage(rowPerPage)}
      >
        <CustomTable.Header columns={POWER_USER_COLUMNS} onRequestSort={handleRequestSort} />

        <CustomTable.Body isLoading={isLoading} deviceData={paginatedList}>
          {paginatedList &&
            paginatedList.map(
              (row: AdminListTypes) =>
                row && (
                  <TableRow key={row.Username}>
                    {powerUserColumns.map((column) => {
                      const value = row[column.id as keyof AdminListTypes];

                      return (
                        <TableBodyCell key={column.id}>{column.format ? column.format(value) : value}</TableBodyCell>
                      );
                    })}

                    <TableBodyCell style={{ width: 30 }}>
                      <Button onClick={(e) => onClickMenu(e, row)} style={{ color: theme.palette.common.black }}>
                        <MoreVertIcon />
                      </Button>
                    </TableBodyCell>
                  </TableRow>
                ),
            )}
        </CustomTable.Body>
      </CustomTable>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onDeleteUser}>
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog fullWidth maxWidth="xs" open={isOpenNewUserModal} onClose={onModalClose} disableEscapeKeyDown>
        <DialogTitle>New Power User</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <StyledTextField
              label="Power User Name"
              {...register("userName", {
                required: "PowerUser Name is required",
                validate: {
                  nameValidationCheck,
                },
              })}
              error={errors.userName}
            />

            {errors.userName && <FieldError>{errors.userName.message}</FieldError>}

            <StyledTextField
              label="Email"
              {...register("email", {
                required: "Email is required!",
                pattern: {
                  value: emailRegex,
                  message: "Entered value does not match email format!",
                },
              })}
              error={errors.email}
            />

            {errors.email && <FieldError>{errors.email.message}</FieldError>}

            <PhoneInputWrapper
              international
              inputComponent={CustomPhoneNumber}
              defaultCountry="US"
              countryCallingCodeEditable={false}
              value={phoneNum}
              onChange={(e) => {
                setPhoneNum(e);
              }}
            />
            {formState?.submitCount > 0 && (
              <FieldError>
                {phoneNum
                  ? isPossiblePhoneNumber(phoneNum)
                    ? undefined
                    : "Phone number is not valid!"
                  : "Phone number is required!"}
              </FieldError>
            )}

            <PageFooter>
              <GhostButton onClick={closeModal}>Cancel</GhostButton>
              <PrimaryButton onClick={handleSubmit(onCreateNewUser)} disabled={isCreatingNewUser}>
                Create
              </PrimaryButton>
            </PageFooter>
          </Box>
        </DialogContent>
      </Dialog>

      <Confirmation />
    </>
  );
};

export default PowerUsersPage;
