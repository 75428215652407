import { Typography } from "@mui/material";
import { Popup } from "components/common/popup/popup.component";
import { Spinner } from "components/common/spinner/spinner.component";
import { useRequest } from "hooks/useRequest";
import { VPN_CONNECTED } from "pages/Devices/Detail/device-detail.page";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";

export default function LaunchMissionControlPage() {
  const navigate = useNavigate();
  const { sn: serialNumber } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // Initially load vpn data and check if 'vpnStatus' is 'Connected'.
  const { data: vpnData } = useRequest(`vpnUIHook?serialNumber=${serialNumber}`);

  const onIframeLoad = async () => {
    try {
      if (vpnData.vpnStatus !== VPN_CONNECTED) {
        setIsLoading(false);
        setIsError(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.warn("error", error);
    }
  };

  return (
    <>
      <iframe
        id="inlineFrameExample"
        title="Inline Frame Example"
        width="100%"
        height="100%"
        src={vpnData.mcUrl}
        onLoad={onIframeLoad}
      />
      <Popup isOpen={isLoading} title="Page is loading...">
        <Spinner />
      </Popup>
      <Popup
        isOpen={isError}
        title="Info"
        primaryBtn={{ onClick: () => navigate(`/devices/${serialNumber}`), text: "Cancel" }}
      >
        <Typography>The vpn has disconnected. Please check the device and connect again.</Typography>
      </Popup>
    </>
  );
}
