import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { KeyedMutator } from "swr";
import { PrimaryButton } from "components/common/Buttons";
import { compareSoftwareVersions, isMegaSoftware } from "utils/general";
import { PageSection } from "components/common/Wrapper";
import { changeDeviceStatusFromUI } from "api/DeviceHelpers";
import useConfirm from "hooks/useConfirm";
import { SectionHeader } from "./DeviceDetail.style";
import MissionControlButton from "./components/mission-control-button.component";
import { DeviceValueTypes } from "../devices.config";

const MEGAEI_REFRESH_MIN_VERSION: string = "MegaFI-2.4.34";
const EI_REFRESH_MIN_VERSION: string = "EI-2.4.16";

interface DetailActionsProps {
  deviceDetail: DeviceValueTypes;
  mutatePendingActions: KeyedMutator<any>;
  mutateDetail: KeyedMutator<any>;
  launchMissionControl: () => void;
}

const DetailActions = ({
  deviceDetail,
  mutatePendingActions,
  mutateDetail,
  launchMissionControl,
}: DetailActionsProps): JSX.Element => {
  const [getConfirmation, Confirmation] = useConfirm();

  const canRefreshValues = useMemo(() => {
    if (!deviceDetail) {
      return false;
    }

    if (isMegaSoftware(deviceDetail.eiSoftwareVersion)) {
      return compareSoftwareVersions(deviceDetail.eiSoftwareVersion, MEGAEI_REFRESH_MIN_VERSION) >= 0;
    }
    return compareSoftwareVersions(deviceDetail.eiSoftwareVersion, EI_REFRESH_MIN_VERSION) >= 0;
  }, [deviceDetail]);

  const handleRefresh = async (): Promise<void> => {
    const isRefreshValues = await getConfirmation({
      title: "Refresh Values",
      content: "Are you sure to refresh values?",
    });

    if (!isRefreshValues) {
      return;
    }

    await changeDeviceStatusFromUI({
      eiSerialNumber: deviceDetail.eiSerialNumber,
      getDeviceDetails: true,
    });

    mutatePendingActions?.();
  };

  const handleReboot = async (): Promise<void> => {
    const isRebootDevice = await getConfirmation({
      title: "Reboot Device",
      content: "Are you sure? You will lose connectivity while the device reboots.",
    });

    if (!isRebootDevice) {
      return;
    }

    await changeDeviceStatusFromUI({
      eiSerialNumber: deviceDetail.eiSerialNumber,
      rebootDevice: true,
    });

    mutatePendingActions?.();
  };

  const handleLocationTrail = async (): Promise<void> => {
    const isConfirmed = await getConfirmation({
      title: "Location Tracking",
      content: `Are you sure you want to ${deviceDetail.locationTrailEnabled ? "disable" : "enable"} location tracking`,
    });

    if (!isConfirmed) {
      return;
    }

    const data = deviceDetail.locationTrailEnabled
      ? { disableLocationTrail: true }
      : { enableLocationTrail: true, getDeviceDetails: true };

    await changeDeviceStatusFromUI({
      eiSerialNumber: deviceDetail.eiSerialNumber,
      ...data,
    });

    mutatePendingActions?.();
    mutateDetail?.();
  };

  return (
    <PageSection>
      <SectionHeader>
        <span className="header-title">Device Actions</span>
      </SectionHeader>
      {deviceDetail ? (
        <Box display="flex" flexDirection="column" gap={2} maxWidth={240} margin="auto">
          <PrimaryButton
            variant="contained"
            onClick={handleRefresh}
            disabled={!deviceDetail.online || !canRefreshValues}
          >
            Refresh Values
          </PrimaryButton>
          <PrimaryButton variant="contained" onClick={handleReboot} disabled={!deviceDetail.online}>
            Reboot Device
          </PrimaryButton>
          <PrimaryButton variant="contained" onClick={handleLocationTrail}>
            {deviceDetail.locationTrailEnabled ? "Disable" : "Enable"} Location Tracking
          </PrimaryButton>
          <MissionControlButton deviceDetail={deviceDetail} onClick={launchMissionControl} />
        </Box>
      ) : null}
      <Confirmation />
    </PageSection>
  );
};

export default DetailActions;
