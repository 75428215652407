import { Dialog, DialogContent } from "@mui/material";
import React, { ReactNode } from "react";
import { GhostButton, PrimaryButton } from "../Buttons";
import style from "./popup.module.css";
import { PopupHeader } from "./popup-header.component";

interface ButtonProps {
  onClick: () => void;
  text: string;
}

interface PopupProps {
  isOpen: boolean;
  onX?: () => void;
  primaryBtn?: ButtonProps;
  secondaryBtn?: ButtonProps;
  title: string;
  children: ReactNode;
}

export function Popup({ onX, isOpen, title, children, primaryBtn, secondaryBtn }: PopupProps): JSX.Element {
  function getButtonContainerStyle() {
    if (primaryBtn === undefined && secondaryBtn === undefined) {
      return "";
    }

    const classes = [style.topMargin];

    if (!secondaryBtn) {
      classes.push(style.alignContentEnd);
    } else {
      classes.push(style.alignContentEvenly);
    }

    return classes.join(" ");
  }

  return (
    <Dialog className={style.container} fullWidth maxWidth="xs" open={isOpen}>
      <PopupHeader title={title} onClick={onX} />
      <DialogContent>
        {children}

        <div className={getButtonContainerStyle()}>
          {secondaryBtn && (
            <div style={{ marginRight: "32px" }}>
              <GhostButton onClick={secondaryBtn.onClick}>{secondaryBtn.text}</GhostButton>
            </div>
          )}
          {primaryBtn && <PrimaryButton onClick={primaryBtn.onClick}>{primaryBtn.text}</PrimaryButton>}
        </div>
      </DialogContent>
    </Dialog>
  );
}
