import React, { PropsWithChildren, useState } from "react";
import { LegalFooter } from "components/common/legal-footer/legal-footer.component";
import { Popup } from "components/common/popup/popup.component";
import { CommonText } from "components/common/text/text.component";
import style from "./page-content.module.css";

export function PageContent({ children }: PropsWithChildren): JSX.Element {
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  return (
    <div className={style.container}>
      {children}
      <LegalFooter onClick={() => setIsTermsOpen(true)} />

      <Popup title="Terms of Service" isOpen={isTermsOpen} onX={() => setIsTermsOpen(false)}>
        <div className={style.textContainer}>
          <CommonText>
            Please note that the location, signal strength, and other information displayed on our platform are reported
            by the devices themselves. We do not independently verify this information and make no specific claims
            regarding its accuracy.
            <br />
            <br /> Users should be aware that the data provided is intended for general informational purposes only.
            Variations in signal strength and location accuracy may occur due to factors beyond our control, such as
            environmental conditions, device performance, and network configurations. <br />
            <br />
            By using our services, you acknowledge and agree that:
            <br /> 1. No Guarantee of Accuracy: We do not guarantee the precision, completeness, or reliability of the
            information provided by the devices. <br />
            2. User Responsibility: You are responsible for any actions taken based on the information reported by the
            devices. We recommend verifying critical information independently.
            <br /> 3. Limitation of Liability: We shall not be held liable for any errors, inaccuracies, or omissions in
            the information provided, nor for any loss or damage resulting from reliance on the data. <br />
            <br />
            For further details, please review our complete Terms of Service. If you have any questions or require
            assistance, please contact our support team.
          </CommonText>
        </div>
      </Popup>
    </div>
  );
}
