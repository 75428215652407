import React from "react";
import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

interface MyButtonProps extends ButtonProps {
  nodeId?: string;
  background?: string;
}

export const TextButton = (props: MyButtonProps): JSX.Element => (
  <Button
    sx={{
      padding: "10px 20px",
      fontSize: "14px",
      textWrap: "noWrap",
      textTransform: "none",
      height: "42px",
      "&:hover": {
        backgroundColor: "#F5F5FA",
      },
    }}
    variant="text"
    disableRipple
    {...props}
  />
);

export const PrimaryButton = (props: ButtonProps): JSX.Element => (
  <Button
    sx={{
      boxShadow: "none",
      "&:active": {
        webkitBoxShadow: "inset 0px 0px 8px 0px rgba(47,104,134,1)",
        mozBoxShadow: "inset 0px 0px 8px 0px rgba(47,104,134,1)",
        boxShadow: "inset 0px 0px 8px 0px rgba(47,104,134,1)",
      },
      "&:after": {
        boxShadow: "none",
      },
      textTransform: "none",
      margin: "8px",

      padding: "10px 40px",
      fontSize: "14px",
      textWrap: "nowrap",
      borderRadius: "50px",
      minWidth: "130px",
      height: "42px",
      "&:hover": {
        backgroundColor: "#4faee0",
      },
    }}
    variant="contained"
    color="primary"
    disableRipple
    {...props}
  />
);

export const GhostButton = (props: ButtonProps): JSX.Element => (
  <Button
    sx={{
      "&:active": {
        webkitBoxShadow: "inset 0px 0px 8px 0px rgba(47,104,134,1)",
        mozBoxShadow: "inset 0px 0px 8px 0px rgba(47,104,134,1)",
        boxShadow: "inset 0px 0px 8px 0px rgba(47,104,134,1)",
      },
      "&:after": {
        boxShadow: "none",
      },
      padding: "10px 40px",
      minWidth: "130px",
      fontSize: "14px",
      textWrap: "nowrap",
      margin: "8px",
      borderRadius: "50px",
      textTransform: "none",
      boxShadow: "none",
      height: "42px",
      borderWidth: "2px",
    }}
    variant="outlined"
    color="primary"
    disableRipple
    {...props}
  />
);

const StyledLoadingButton = styled(LoadingButton)`
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  font-family: var(--font-primary);
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 8px;
  text-transform: none;
`;

export const AuthButton = (props: ButtonProps) => (
  <StyledLoadingButton variant="contained" color="primary" {...props} />
);
