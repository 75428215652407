export const ACTION_JSON: string = "actionJson";

export interface PendingGridColumnsTypes {
  id: string;
  label: string;
  minWidth: number;
  maxWidth: number;
  align?: "center" | "left" | "right" | "inherit" | "justify" | undefined;
  format?: (arg: any) => string;
}

const PENDING_GRID_COLUMNS: PendingGridColumnsTypes[] = [
  { id: "eiSerialNumber", label: "Serial No.", minWidth: 70, maxWidth: 70 },
  {
    id: "completed",
    label: "Completed",
    minWidth: 100,
    maxWidth: 100,
    align: "center",
    format: (value: any) => value.toLocaleString("en-US"),
  },
  {
    id: "attempts",
    label: "Attempts",
    align: "center",
    minWidth: 70,
    maxWidth: 70,
    format: (value: any) => value.toLocaleString("en-US"),
  },
  {
    id: "maxAttempts",
    label: "Max Attempts",
    minWidth: 100,
    maxWidth: 100,
    align: "center",
    format: (value: any) => value.toLocaleString("en-US"),
  },
  { id: ACTION_JSON, label: "Pending Action", minWidth: 200, maxWidth: 200, align: "left" },
];

export default PENDING_GRID_COLUMNS;
