import { FormControlLabel, Checkbox, Slider } from "@mui/material";
import { PrimaryButton } from "components/common/Buttons";
import { CommonText } from "components/common/text/text.component";
import { RouteToolsButtonsWrapper } from "pages/Map/MapPage.style";
import React from "react";
import style from "./route-tools.module.css";

interface RouteToolsProps {
  toggleDeviceRoute: () => void;
  valueLabelFormat: string | ((value: number, index: number) => React.ReactNode) | undefined;
  onChange: (arg: any) => void;
  sliderStep: number[];
  sliderTotalSteps: number;
  getMapDeviceData: () => void;
}

export function RouteTools({
  sliderStep,
  sliderTotalSteps,
  onChange,
  toggleDeviceRoute,
  valueLabelFormat,
  getMapDeviceData,
}: RouteToolsProps): JSX.Element {
  return (
    <>
      <RouteToolsButtonsWrapper>
        <FormControlLabel
          onClick={() => toggleDeviceRoute()}
          control={<Checkbox />}
          label={<CommonText>Show Device Route</CommonText>}
        />
      </RouteToolsButtonsWrapper>
      <div className={style.markerWrapper}>
        <div className={style.mapToolsWrapper}>
          <div className={style.sliderContainer}>
            <Slider
              getAriaLabel={() => "Route"}
              getAriaValueText={() => ""}
              valueLabelFormat={valueLabelFormat}
              step={1}
              onChange={(event, value) => {
                onChange(value);
              }}
              value={sliderStep}
              marks
              min={1}
              max={sliderTotalSteps}
            />
            <div className={style.sliderLabelContainer}>
              <CommonText>Route Start</CommonText>
              <CommonText>Route End</CommonText>
            </div>
          </div>
          <div>
            <PrimaryButton onClick={getMapDeviceData}>Download Data</PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
}
