import React from "react";
import { useRequest } from "hooks/useRequest";
import { compareSoftwareVersions, isMegaSoftware } from "utils/general";
import { PrimaryButton } from "components/common/Buttons";
import { DeviceValueTypes } from "pages/Devices/devices.config";

interface MissionControlButtonProps {
  deviceDetail: DeviceValueTypes;
  onClick: () => void;
}

export default function MissionControlButton({ deviceDetail, onClick }: MissionControlButtonProps): JSX.Element {
  const { data: megaFiVerNum } = useRequest("minFwVerForRM");

  if (!megaFiVerNum || megaFiVerNum === "") {
    return <div />;
  }

  if (!deviceDetail.online) {
    return <div />;
  }

  if (
    !isMegaSoftware(deviceDetail.eiSoftwareVersion) ||
    !(compareSoftwareVersions(deviceDetail.eiSoftwareVersion, megaFiVerNum) >= 0)
  ) {
    return <div />;
  }

  return (
    <PrimaryButton variant="contained" onClick={onClick}>
      Launch Mission Control
    </PrimaryButton>
  );
}
