import React, { BaseSyntheticEvent, ReactNode } from "react";
import { TextButton } from "../Buttons";
import style from "./nxt-icon-button.module.css";

interface NxtIconButtonProps {
  icon: ReactNode;
  onClick: (e?: BaseSyntheticEvent) => void;
  isDisabled?: boolean;
  text: string;
}

export function NxtIconButton({ icon, onClick, isDisabled, text }: NxtIconButtonProps): JSX.Element {
  return (
    <TextButton disableRipple onClick={onClick} disabled={isDisabled}>
      <div className={style.iconWrapper}>{icon}</div>
      {text}
    </TextButton>
  );
}
